import React, { useEffect, useState } from "react";
import Loader from "../Loader"; // Import Loader component
import MainService from "../../services/main-service";
import { Checkbox, Tabs, Tooltip, message, Input, Button } from "antd";
import { DefaultSkeleton } from "../theme/loader/defaultSkeletonComponent";
import InfoIcon from "../theme/icon/infoIcon";
import TabPane from "antd/es/tabs/TabPane";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "../../views/style/global.css";
import { formatDate, getPlanInterval } from "../constant/helper";
import { getApplication } from "../../helper/utils";
 
const Configuration = (props) => {
  const { tabActive, syncCustomLoader, setSyncCustomLoader } = props;
  const [customLoader, setCustomLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [configTabActive, setConfigTabActive] = useState("0");
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const application_id = getApplication();
 
  const [configFormData, setConfigFormData] = useState({
    application_id,
    odoo_url: "",
    odoo_db_name: "",
    odoo_userName: "",
    odoo_password: "",
    isAutoSyncCustomer: false,
    isAutoSyncProduct: false,
    isAutoSyncOrder: false,
    isAutoSyncPaymentMethod: false,
  });
 
  const fetchOauthData = async () => {
    setCustomLoader(true);
    try {
      const response = await MainService.getOauthAccount(application_id);
 
      if (response.status === 200) {
        const data = response.data.data;
 
        setConfigFormData({
          application_id,
          odoo_url: data.odoo_url,
          odoo_db_name: data.odoo_db_name,
          odoo_userName: data.odoo_userName,
          odoo_password: "",
          isAutoSyncCustomer: data.isAutoSyncCustomer,
          isAutoSyncProduct: data.isAutoSyncProduct,
          isAutoSyncOrder: data.isAutoSyncOrder,
          isAutoSyncPaymentMethod: data.isAutoSyncPaymentMethod,
        });
 
        setCustomLoader(false);
      }
    } catch (error) {
      setCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };
  const validateForm = () => {
    let tempErrors = {};
    if (!configFormData.odoo_url) {
      tempErrors.odoo_url = "Odoo URL is required";
    } else if (!/^https:\/\/[\w]+\.odoo\.com$/.test(configFormData.odoo_url)) {
      tempErrors.odoo_url =
        "Odoo URL is invalid. Format should be like https://example.odoo.com";
    }
    if (!configFormData.odoo_db_name) {
      tempErrors.odoo_db_name = "Database name is required";
    }
    if (!configFormData.odoo_userName) {
      tempErrors.odoo_userName = "Username is required";
    }
 
    return tempErrors;
  };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfigFormData({
      ...configFormData,
      [name]: value, // Update the value of the corresponding input field
    });
    if (value) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setSyncCustomLoader(true); // Set loading to true when the form is submitted
        const res = await MainService.updateOauth(configFormData);
 
        if (res.status === 200) {
          setSyncCustomLoader(false);
          message.success(res.data.message);
        }
      } catch (error) {
        const { response } = error;
        message.error(response.data.message);
        setSyncCustomLoader(false); // Ensure loading is set to false on error
      }
    }
  };
 
  const fetchSubscriptionDetails = async () => {
    const subscriptionDetail = await MainService.getSubscriptionDetails();
    setSubscriptionDetail(subscriptionDetail.data.data);
  };
 
  useEffect(() => {
    if (tabActive === "1") {
      setConfigTabActive("0");
      fetchOauthData();
      fetchSubscriptionDetails();
    }
  }, [tabActive]);
 
  const tabConfigList = [
    {
      key: "0",
      label: "Odoo Configuration",
    },
    {
      key: "1",
      label: "Set Default Configuration",
    },
    // {
    //   key: "2",
    //   label: "Subscription Details",
    // },
  ];
 
  const tabSetConfigList = [
    {
      label: "Auto Sync New Customer",
      value: "isAutoSyncCustomer",
      isDisabled: false,
    },
    {
      label: "Auto Sync New Product",
      value: "isAutoSyncProduct",
      isDisabled: false,
    },
    {
      label: "Auto Sync New Order",
      value: "isAutoSyncOrder",
      isDisabled: false,
    },
    {
      label: "Auto Sync New Payment Method",
      value: "isAutoSyncPaymentMethod",
      isDisabled: false,
    },
  ];
 
  const onChangeConfigTab = async (activeKey) => {
    setConfigTabActive(activeKey);
  };
 
  const onChange = (e) => {
    const { checked, id } = e.target;
    let updatedConfig = { ...configFormData };
 
    if (id === "isAutoSyncPaymentMethod" && checked) {
      // If payment is checked, check all others
      updatedConfig = {
        ...updatedConfig,
        isAutoSyncCustomer: true,
        isAutoSyncProduct: true,
        isAutoSyncOrder: true,
        isAutoSyncPaymentMethod: true
      };
    } else if (id === "isAutoSyncOrder" && checked) {
      // If order is checked, check customer and product
      updatedConfig = {
        ...updatedConfig,
        isAutoSyncCustomer: true,
        isAutoSyncProduct: true,
        isAutoSyncOrder: true,
        [id]: checked
      };
    } else if ((id === "isAutoSyncCustomer" || id === "isAutoSyncProduct") && !checked) {
      // If unchecking customer or product, uncheck order and payment
      if (updatedConfig.isAutoSyncOrder || updatedConfig.isAutoSyncPaymentMethod) {
        updatedConfig = {
          ...updatedConfig,
          isAutoSyncOrder: false,
          isAutoSyncPaymentMethod: false,
          [id]: checked
        };
      } else {
        updatedConfig[id] = checked;
      }
    } else {
      updatedConfig[id] = checked;
    }
 
    setConfigFormData(updatedConfig);
  };
 
  const handleCancelSubscription = async () => {
    const res = await MainService.cancelSubscription(configFormData);
    console.log("🚀 -----------------------🚀")
    console.log("🚀 ~ res:", res)
    console.log("🚀 -----------------------🚀")
    if(res.status === 200){
      message.success(res.data.message);
    }
    else{
      message.error(res.data.message);
    }
   
  }
 
  return (
    <div>
      {syncCustomLoader && <DefaultSkeleton />}
      {customLoader ? (
        <Loader />
      ) : (
        <Tabs activeKey={configTabActive} onChange={onChangeConfigTab}>
          {tabConfigList.map((tab) => (
            <TabPane
              key={tab.key}
              tab={
                <div className={`flex items-center`}>
                  <span className='ml-2 text-sm font-normal'>{tab.label}</span>
                </div>
              }
            >
              <div className='application-container pl-6 relative'>
                {(configTabActive === "0" || configTabActive === "1") &&
                  <form onSubmit={handleSubmit} className='form'>
                    {configTabActive === "0" ? (
                      <div>
                        <div className='text-black text-sm font-normal mb-2 mt-4'>
                          Odoo url
                        </div>
                        <input
                          type='text'
                          name='odoo_url'
                          value={configFormData.odoo_url}
                          onChange={handleChange}
                          placeholder='Write your odoo url'
                          className='auth-input'
                        />
                        {errors.odoo_url && (
                          <div className='text-red-600 text-sm mt-1'>
                            {errors.odoo_url}
                          </div>
                        )}
 
                        <div>
                          <div className='text-black text-sm font-normal mb-2 mt-2'>
                            DB Name
                          </div>
                          <input
                            type='text'
                            name='odoo_db_name'
                            value={configFormData.odoo_db_name}
                            onChange={handleChange}
                            placeholder='Write your database name'
                            className='auth-input'
                          />
                          {errors.odoo_db_name && (
                            <div className='text-red-600 text-sm mt-1'>
                              {errors.odoo_db_name}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className='text-black text-sm font-normal mb-2 mt-2'>
                            Username
                          </div>
                          <input
                            type='text'
                            name='odoo_userName'
                            value={configFormData.odoo_userName}
                            onChange={handleChange}
                            placeholder='Write your username'
                            className='auth-input'
                          />
                          {errors.odoo_userName && (
                            <div className='text-red-600 text-sm mt-1'>
                              {errors.odoo_userName}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className='text-black text-sm font-normal mb-2 mt-2'>
                            Password
                            <div className='relative inline-block ml-2 align-middle cursor-pointer group'>
                              <Tooltip
                                placement='rightTop'
                                title='Keep it blank if you do not want to change your password!'
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                          <Input.Password
                            name='odoo_password'
                            value={configFormData.odoo_password}
                            onChange={handleChange}
                            placeholder='Write your password'
                            className='auth-input'
                            iconRender={(visible) =>
                              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                          />
                          {errors.odoo_password && (
                            <div className='text-red-600 text-sm mt-1'>
                              {errors.odoo_password}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {tabSetConfigList.map((tab) => {
                          let isDisabled = false;
                         
                          // Disable customer if order or payment is checked
                          if (tab.value === "isAutoSyncCustomer") {
                            isDisabled = configFormData.isAutoSyncOrder || configFormData.isAutoSyncPaymentMethod;
                          }
                          // Disable product if order or payment is checked
                          else if (tab.value === "isAutoSyncProduct") {
                            isDisabled = configFormData.isAutoSyncOrder || configFormData.isAutoSyncPaymentMethod;
                          }
                          // Disable order if payment is checked
                          else if (tab.value === "isAutoSyncOrder") {
                            isDisabled = configFormData.isAutoSyncPaymentMethod;
                          }
 
                          return (
                            <Checkbox
                              className='custom-checkbox w-[350px]'
                              onChange={onChange}
                              checked={configFormData[tab.value]}
                              disabled={isDisabled}
                              id={tab.value}
                            >
                              {tab.label}
                            </Checkbox>
                          );
                        })}
                      </div>
                    )
                    }
                    {(configTabActive === "0" || configTabActive === "1") && (
                      <button type='submit' className='auth-button mt-5'>
                        Update changes
                      </button>
                    )}
                  </form>
                }
                {configTabActive === "2" && (
                  <div className='p-6 flex gap-6'>
                    {/* Current Plan Section */}
                    <div className='bg-white p-6 rounded-md shadow-md border border-gray-200 w-[50%]'>
                      <h2 className='text-lg font-bold mb-4'>Current plan</h2>
 
                      <div className='grid grid-cols-2 gap-4'>
                        <div>
                          <h3 className='text-gray-700 font-medium'>PLAN</h3>
                          <p className='text-lg font-semibold'>
                            {subscriptionDetail.plan.name}
                          </p>
                          {/* <a href="#" className="text-blue-500 text-sm underline">
                              Upgrade to Business
                            </a> */}
                        </div>
 
                        <div>
                          <h3 className='text-gray-700 font-medium'>
                            BILLING CYCLE
                          </h3>
                          <p className='text-lg font-semibold'>
                            {getPlanInterval(
                              subscriptionDetail.plan.interval
                            )}
                          </p>
                          {/* <a href="#" className="text-blue-500 text-sm underline">
                              Switch to annual plan
                            </a>
                            <p className="text-green-500 text-xs mt-1">SAVE $144 PER YEAR</p> */}
                        </div>
 
                        <div>
                          <h3 className='text-gray-700 font-medium'>
                            BILLING DATE
                          </h3>
                          <p className='text-sm'>
                            <strong>Last billing:</strong>{" "}
                            {formatDate(
                              subscriptionDetail.subscription_start
                            )}
                          </p>
                          <p className='text-sm'>
                            <strong>Next billing:</strong>{" "}
                            {formatDate(
                              subscriptionDetail.subscription_end
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
 
                    {/* Cancel Subscription Section */}
                    <div className='bg-white p-6 rounded-md shadow-md border border-gray-200 w-[50%]'>
                      <h2 className='text-lg font-bold mb-4'>
                        Cancel subscription
                      </h2>
                      <p className='text-sm text-gray-600 mb-4'>
                        Canceled subscription will remain active until the end
                        of the current billing period.
                      </p>
                      {/* onClick={() => syncPopup(tab.label)} */}
                      <Button onClick={handleCancelSubscription} className='bg-[#A90083] text-white border border-[#A90083] hover:bg-[#ff84e4] hover:text-white'>
                        Cancel subscription
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
};
export default Configuration;