import { createBrowserRouter } from "react-router-dom";
import { routeGuard } from "./guard";

import App from "../App";
import Header from "../components/header/header";
import Auth from "../views/Auth";
import Customers from "../components/customers/customers";
import Mapping from "../components/mapping/mapping";
import SubscriptionPlan from "../views/SubscriptionPlan";
import Home from "../views/Home";

const router = createBrowserRouter([
  {
    path: "/company/:company_id/application/:application_id",
    element: <App />,
    loader: routeGuard,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/customer/list",
    element: <Customers />,
  },
  {
    path: "/mapping",
    element: <Mapping />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },

  {
    path: "/dashboard",
    element: <Header />,
  },
  {
    path: "/mapping",
    element: <Mapping />,
  },
  {
    path: "/subscriptionPlan",
    element: <SubscriptionPlan />,
  },
]);

export default router;
