const getCapitalizeOnefirstletter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

const getPlanInterval = (plan) => {
    switch (plan.toLowerCase()) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Yearly';
      case 'quarter':
        return 'Quarterly';
      default:
        return plan.toLowerCase();
    }
  };

export {
    getCapitalizeOnefirstletter,
    formatDate,
    getPlanInterval
}