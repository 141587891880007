export const DEFAULT_TABLE_LIMIT = 10;

export const Customer_Filed = [
    {
        key: "name",
        value: "name",
      },
      {
        key: "email",
        value: "email",
      },
      {
        key: "phone",
        value: "phone",
      },
      {
        key: "city",
        value: "city",
      },
      {
        key: "addressLine1",
        value: "street",
      },
      {
        key: "addressLine2",
        value: "street2",
      },
      {
        key: "addressType",
        value: "type",
      },
      {
        key: "pincode",
        value: "zip",
      },
      {
        key: "state",
        value: "state_id",
      },
      {
        key: "country",
        value: "country_id",
      },
];

export const Product_Filed = [
  {
      key: "name",
      value: "name",
    },
    {
      key: "slug",
      value: "barcode",
    },
    {
      key: "selling_price",
      value: "list_price",
    },
    {
      key: "cost",
      value: "standard_price",
    },
    {
      key: "image",
      value: "image_1920",
    },
    {
      key: "hsn_code",
      value: "l10n_in_hsn_code",
    },
    {
      key: "currency",
      value: "currency_id",
    },
    // {
    //   key: "product_id",
    //   value: "product_id",
    // },
];
export const Order_Filed = [
  {
    key: "order_created_time",
    value: "date_order"
  },
  {
    key: "status",
    value: "state" 
  },
  {
    key: "order_line",
    value: "order_line"
  },
  {
    key: "customer_id",
    value: "partner_id"
  },
  {
    key: "user_id",
    value: "user_id"
  },

];
export const Payment_Filed = [
    {
      key: "current_status",
      value: "state"
    },
    {
      key: "payment_date",
      value: "date"
    },
    {
      key: "move_type",
      value: "move_type"
    },
    {
      key: "customer_id",
      value: "partner_id"
    },
    {
      key: "payment_method",
      value: "payment_method"
    },
];

export const PriceSymbol = Object.freeze({
  USD: '$',
  EUR: '€',
  GBP: '£',
  INR: '₹',
});
