import * as React from "react";

const PaymentIcon = (props) => (
    React.createElement("svg", {
        width: 20,
        height: 17,
        viewBox: "0 0 20 17",
        fill: "currentcolor",
        xmlns: "http://www.w3.org/2000/svg",
        ...props
    },
        React.createElement("path", {
            d: "M4.5 13.35C4.51875 13.0687 4.6875 12.9375 4.96875 12.9187H6.75C7.03125 12.9375 7.2 13.0687 7.21875 13.35C7.2 13.6313 7.03125 13.7625 6.75 13.7812H4.96875C4.6875 13.7625 4.51875 13.6313 4.5 13.35ZM8.1 13.35C8.11875 13.0687 8.2875 12.9375 8.56875 12.9187H12.15C12.4313 12.9375 12.6 13.0687 12.6188 13.35C12.6 13.6313 12.4313 13.7625 12.15 13.7812H8.56875C8.2875 13.7625 8.11875 13.6313 8.1 13.35ZM1.8 5.7C1.81875 5.19375 2.00625 4.77188 2.41875 4.35938C2.83125 3.94688 3.25313 3.75938 3.75 3.75H16.2C16.6969 3.75938 17.1188 3.94688 17.5312 4.35938C17.9437 4.77188 18.1312 5.19375 18.15 5.7V14.7C18.1312 15.2062 17.9437 15.6281 17.5312 16.0406C17.1188 16.4531 16.6969 16.6406 16.2 16.65H3.75C3.25313 16.6406 2.83125 16.4531 2.41875 16.0406C2.00625 15.6281 1.81875 15.2062 1.8 14.7V5.7ZM2.7 5.7V6.6H17.1V5.7C17.1 5.4375 17.0062 5.22188 16.8375 5.05313C16.6688 4.88438 16.4531 4.8 16.2 4.8H3.75C3.49688 4.8 3.28125 4.88438 3.1125 5.05313C2.94375 5.22188 2.7 5.4375 2.7 5.7ZM2.7 9.3H17.1V7.5H2.7V9.3ZM2.7 10.2V14.7C2.7 14.9625 2.79375 15.1781 2.9625 15.3469C3.13125 15.5156 3.34688 15.6 3.75 15.6H16.2C16.4531 15.6 16.6688 15.5156 16.8375 15.3469C17.0062 15.1781 17.1 14.9625 17.1 14.7V10.2H2.7Z",
            fill: "currentcolor",
            // fillOpacity: 0.88
        })
    )
);

export default PaymentIcon;
