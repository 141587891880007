import URLS from "./endpoint.service";
import axios from "axios";
import { getApplication, getCompany } from "../helper/utils";

axios.interceptors.request.use((config) => {
  config.headers["x-company-id"] = getCompany();
  config.headers["x-applicaion-id"] = getApplication();
  return config;
});

const MainService = {
  //V1 Router Funcation
  getAllApplications(params = {}) {
    return axios.get(URLS.GET_ALL_APPLICATIONS());
  },
  fyndAllDataInsert(application_id) {
    return axios.post(URLS.FYND_ALL_DATA_INSERT(application_id));
  },

  //FyndOuth Router Funcation
  createOauth(data) {
    return axios.post(URLS.CRETE_OAUTH(), data);
  },
  existOauthAccount(application_id) {
    return axios.post(URLS.EXIST_OAUTH_ACCOUNT(application_id));
  },
  getOauthAccount(application_id) {
    return axios.get(URLS.GET_OAUTH_ACCOUNT(), {
      params: { application_id },
    });
  },
  updateOauth(data) {
    return axios.post(URLS.UPDATE_OAUTH(), data);
  },

  //Customer Router Funcation
  getAllCustomers(
    application_id,
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) {
    return axios.get(URLS.GET_ALL_CUSTOMERS(), {
      params: {
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue,
      },
    });
  },
  getCustomersDetails(application_id, id) {
    return axios.get(URLS.GET_CUSTOMER_DETAILS(), {
      params: { application_id, id },
    });
  },
  syncCustomersDetail(application_id, sync_id) {
    return axios.post(URLS.SYNC_CUSTOMER_DETAILS(application_id, sync_id));
  },
  syncAllCustomersDetail(application_id) {
    return axios.post(URLS.SYNC_ALL_CUSTOMER_DETAILS(application_id));
  },
  getTotalSyncCustomer(application_id) {
    return axios.get(URLS.GET_TOTAL_SYNC_CUSTOMER(), {
      params: { application_id },
    });
  },

  //Mapping Router Funcation
  creteCustomMapping(application_id, data, model_name) {
    return axios.post(URLS.CRETE_CUSTOM_MAPPING(), {
      application_id,
      data,
      model_name,
    });
  },
  getCustomerFeilds(application_id) {
    return axios.get(URLS.GET_CUSTOMER_FEILDS(), {
      params: { application_id },
    });
  },
  getProductFeilds(application_id) {
    return axios.get(URLS.GET_PRODUCT_FEILDS(), {
      params: { application_id },
    });
  },
  getOrderFeilds(application_id) {
    return axios.get(URLS.GET_ORDER_FEILDS(), {
      params: { application_id },
    });
  },

  //Product Router Funcation
  getAllProducts(
    application_id,
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) {
    return axios.get(URLS.GET_ALL_PRODUCTS(), {
      params: {
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue,
      },
    });
  },
  getProductsDetails(application_id, id) {
    return axios.get(URLS.GET_PRODUCT_DETAILS(), {
      params: { application_id, id },
    });
  },
  syncProductsDetail(application_id, sync_id) {
    return axios.post(URLS.SYNC_PRODUCT_DETAILS(application_id, sync_id));
  },
  syncAllProductsDetail(application_id) {
    return axios.post(URLS.SYNC_ALL_PRODUCT_DETAILS(application_id));
  },
  getTotalSyncProduct(application_id) {
    return axios.get(URLS.GET_TOTAL_SYNC_PRODUCT(), {
      params: { application_id },
    });
  },

  // order Router Function
  getAllOrders(
    application_id,
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) {
    return axios.get(URLS.GET_ALL_ORDERS(), {
      params: {
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue,
      },
    });
  },
  getOrderDetails(application_id, id) {
    return axios.get(URLS.GET_ORDER_DETAILS(), {
      params: { application_id, id },
    });
  },
  syncOrdersDetail(application_id, sync_id) {
    return axios.post(URLS.SYNC_ORDER_DETAILS(application_id, sync_id));
  },
  syncAllOrdersDetail(application_id) {
    return axios.post(URLS.SYNC_ALL_ORDER_DETAILS(application_id));
  },
  getTotalSyncOrders(application_id) {
    return axios.get(URLS.GET_TOTAL_SYNC_ORDER(), {
      params: { application_id },
    });
  },
  // Payment Router Function
  getAllPayments(
    application_id,
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) {
    return axios.get(URLS.GET_ALL_PAYMENTS(), {
      params: {
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue,
      },
    });
  },
  getPaymentDetails(application_id, id) {
    return axios.get(URLS.GET_PAYMENT_DETAILS(), {
      params: { application_id, id },
    });
  },
  syncPaymentsDetail(application_id, sync_id) {
    return axios.post(URLS.SYNC_PAYMENT_DETAILS(application_id, sync_id));
  },
  syncAllPaymentsDetail(application_id) {
    return axios.post(URLS.SYNC_ALL_PAYMENT_DETAILS(application_id));
  },
  getTotalSyncPayments(application_id) {
    return axios.get(URLS.GET_TOTAL_SYNC_PAYMENT(), {
      params: { application_id },
    });
  },
  getPaymentFields(application_id) {
    return axios.get(URLS.GET_PAYMENT_FIELDS(), {
      params: { application_id },
    });
  },

  //subscription plan Router Funcation
  getAllSubscriptionPlan(params = {}) {
    return axios.get(URLS.GET_ALL_SUBSCRIPTION_PLAN());
  },

  //subscription Router Funcation
  createSubscription(plan_id, application_id) {
    return axios.post(URLS.CREATE_SUBSCRIPTION(plan_id, application_id));
  },

  getSubscription(params = {}) {
    return axios.get(URLS.GET_SUBSCRIPTION());
  },
  getSubscriptionDetails(params = {}) {
    return axios.get(URLS.GET_SUBSCRIPTION_DETAILS());
  },
  cancelSubscription(data) {
    return axios.post(URLS.CANCEL_SUBSCRIPTION(), data);
  },
};
export default MainService;
