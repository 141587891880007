import React, { useState, useEffect } from "react";
import "./style/dashboard.css";
import Loader from "../components/Loader";
import MainService from "../services/main-service";
import { Button, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getApplication } from "../helper/utils";

export default function SubscriptionPlan() {
  const [pageLoading, setPageLoading] = useState(false);
  const [subscriptionPlanList, setSubscriptionPlanList] = useState([]);
  const application_id = getApplication();
  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  const fetchSubscriptionPlan = async () => {
    setPageLoading(true);
    try {
      console.log("Page")
      const { data } = await MainService.getAllSubscriptionPlan();
      setSubscriptionPlanList(data);
      setPageLoading(false);
    } catch (e) {
      setPageLoading(false);
    }
  };

  const onSubmit = async (plan_id) => {
    setPageLoading(true);
    try {
      const response = await MainService.createSubscription(
        plan_id,
        application_id
      );

      if (response) {
        window.location.href = response.data.confirm_url;
        setPageLoading(false);
      }
      else {
        message.error(response.data.message);
      }
    } catch (error) {
      setPageLoading(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };


  return (
    <>
      {pageLoading ? (
        <Loader />
      ) : (
        <div className="m-5">
          <div className="application-container p-12 mt-6 relative">
            <h1 className="font-semibold text-[38px] mb-3" style={{ textAlign: "center" }}>Choose your Plan</h1>
            <div className="container mx-auto mt-10 flex justify-center">
              <div className="grid grid-cols-2 gap-6">
                {subscriptionPlanList.map((plan, index) => (
                  <div
                    key={index}
                    className={`border-2 p-8 rounded-xl text-center shadow-lg`}
                  >
                    <h2 className="text-2xl font-bold mb-4">{plan.name}</h2>
                    <div className="text-4xl font-bold mb-4">
                      ₹{plan.price} <span className="text-sm font-medium">/ {plan.interval}</span>
                    </div>
                    <ul className="mb-6">
                      {plan.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center justify-start mb-2">
                          <span className="mr-2 text-[#A90083]"><CheckCircleOutlined /></span>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <Button onClick={() => onSubmit(plan._id)} className="bg-[#A90083] text-white border border-[#A90083] hover:bg-[#ff84e4] hover:text-white">
                      Select
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
