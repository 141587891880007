import urlJoin from "url-join";
import root from "window-or-global";
let envVars = root.env || {};

// envVars.EXAMPLE_MAIN_URL = `${root.location.protocol}//${root.location.hostname}`;
envVars.EXAMPLE_MAIN_URL = `${root.location.protocol}//${root.location.hostname}:${root.location.port}`;

if (
  root &&
  root.process &&
  root.process.env &&
  root.process.NODE_ENV === "test"
) {
  envVars.EXAMPLE_MAIN_URL = "https://api.xyz.com";
}

const Endpoints = {
  //V1 Router Funcation
  GET_ALL_APPLICATIONS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, "/api/v1.0/applications");
  },
  FYND_ALL_DATA_INSERT(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/applications/${application_id}`
    );
  },

  //FyndOuth Router Funcation
  CRETE_OAUTH() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, "/api/v1.0/oauth/create");
  },
  EXIST_OAUTH_ACCOUNT(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/oauth/isAuthenticated/${application_id}`
    );
  },
  GET_OAUTH_ACCOUNT() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/oauth/getOauth`);
  },
  UPDATE_OAUTH() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, "/api/v1.0/oauth/update");
  },

  //Customer Router Funcation
  GET_ALL_CUSTOMERS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/customer/list`);
  },
  GET_CUSTOMER_DETAILS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/customer/detail`);
  },
  SYNC_CUSTOMER_DETAILS(application_id, sync_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/customer/sync/${application_id}/${sync_id}`
    );
  },
  SYNC_ALL_CUSTOMER_DETAILS(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/customer/syncAll/${application_id}`
    );
  },
  GET_TOTAL_SYNC_CUSTOMER() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/customer/totalSyncCount`
    );
  },

  //Mapping Customer Router Funcation
  CRETE_CUSTOM_MAPPING() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/mapping/insertUpdate`);
  },
  GET_CUSTOMER_FEILDS() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/mapping/getCustomerField`
    );
  },
  GET_PRODUCT_FEILDS() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/mapping/getProductField`
    );
  },

  //Product Router Funcation
  GET_ALL_PRODUCTS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/product/list`);
  },
  GET_PRODUCT_DETAILS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/product/detail`);
  },
  SYNC_PRODUCT_DETAILS(application_id, sync_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/product/sync/${application_id}/${sync_id}`
    );
  },
  SYNC_ALL_PRODUCT_DETAILS(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/product/syncAll/${application_id}`
    );
  },
  GET_TOTAL_SYNC_PRODUCT() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/product/totalSyncCount`
    );
  },

  //Mapping Product Router Funcation
  CRETE_PRODUCT_MAPPING() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/product/insertUpdate`);
  },
  
  // Order Router Fucntion

  GET_ALL_ORDERS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/order/list`);
  },

  GET_ORDER_DETAILS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/order/detail`);
  },
  SYNC_ORDER_DETAILS(application_id, sync_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/order/sync/${application_id}/${sync_id}`
    );
  },
  SYNC_ALL_ORDER_DETAILS(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/order/syncAll/${application_id}`
    );
  },
  GET_TOTAL_SYNC_ORDER() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/order/totalSyncCount`);
  },
  GET_ORDER_FEILDS() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/mapping/getOrderFields`
    );
  },

  //Payment Router Function

  GET_ALL_PAYMENTS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/payment/list`);
  },
  GET_PAYMENT_DETAILS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/payment/detail`);
  },
  SYNC_PAYMENT_DETAILS(application_id, sync_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/payment/sync/${application_id}/${sync_id}`
    );
  },
  SYNC_ALL_PAYMENT_DETAILS(application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/payment/syncAll/${application_id}`
    );
  },
  GET_TOTAL_SYNC_PAYMENT() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/payment/totalSyncCount`
    );
  },
  GET_PAYMENT_FIELDS() {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/mapping/getPaymentFields`
    );
  },

  //Subscription Plan Router Funcation
  GET_ALL_SUBSCRIPTION_PLAN() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, "/api/v1.0/subscriptionPlan");
  },

  //Subscription Router Funcation
  CREATE_SUBSCRIPTION(plan_id, application_id) {
    return urlJoin(
      envVars.EXAMPLE_MAIN_URL,
      `/api/v1.0/subscription/create/${plan_id}/${application_id}`
    );
  },
  GET_SUBSCRIPTION() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/subscription`);
  },
  GET_SUBSCRIPTION_DETAILS() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/subscription/details`);
  },
  CANCEL_SUBSCRIPTION() {
    return urlJoin(envVars.EXAMPLE_MAIN_URL, `/api/v1.0/subscription/cancel`);
  },
};

export default Endpoints;
