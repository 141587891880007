import React, { useState } from "react";
import { getApplication, getCompany } from "../helper/utils";

const UserStateContext = React.createContext();

const UserProviderContext = ({ children }) => {
  const [userId, setUserId] = useState();

  const value = {
    userId,
    setUserId,
    company_id: getCompany(),
    application_id: getApplication(),
  };

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

export { UserProviderContext, UserStateContext };
