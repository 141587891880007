import { Button, Tabs, message, Modal, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import HomeIcon from "../theme/icon/homeIcon";
import SettingIcon from "../theme/icon/settingIcon";
import ProductIcon from "../theme/icon/productIcon";
import CustomerIcon from "../theme/icon/customerIcon";
import OrderIcon from "../theme/icon/orderIcon";
import PaymentIcon from "../theme/icon/paymentIcon";
import "../../views/style/home.css";
import Dashboard from "../../views/Dashboard";
import Customers from "../customers/customers";
import Payments from "../payments/payments";
import pluralize from "pluralize";
import Configuration from "../configuration/configuration";
import TabPane from "antd/es/tabs/TabPane";
import MainService from "../../services/main-service";
import Mapping from "../mapping/mapping";
import { debounce } from "lodash";
// import Search from "antd/es/input/Search";
import { InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import Products from "../products/products";
import Orders from "../orders/orders";
import { getApplication } from "../../helper/utils";

const Header = () => {
  const [tabActive, setTabActive] = useState("0");
  const [syncCustomLoader, setSyncCustomLoader] = useState(false);
  const application_id = getApplication();
  const [mappingComponent, setMappingComponent] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { confirm } = Modal;
  const { Search } = Input;

  const tabList = [
    {
      key: "0",
      label: "Home",
      icon: <HomeIcon className='mb-1' />,
      component: <Dashboard {...{ tabActive, setTabActive }} />,
      disabled: false,
      des: "Sync data effortlessly between Fynd and Odoo with the Fynd Odoo connectors app to ensure streamlined eCommerce management.",
    },
    {
      key: "1",
      label: "Configuration",
      icon: <SettingIcon />,
      component: (
        <Configuration
          {...{
            tabActive,
            setTabActive,
            syncCustomLoader,
            setSyncCustomLoader,
          }}
        />
      ),
      disabled: false,
      des: "To set up the Fynd Odoo connector, enter Odoo details (URL, DB name, username, password) and proceed with configuring defaults.",
    },
    {
      key: "2",
      label: "Customers",
      icon: <CustomerIcon />,
      component: (
        <Customers
          {...{
            tabActive,
            setTabActive,
            syncCustomLoader,
            setSyncCustomLoader,
            reload,
            setReload,
            searchValue,
          }}
        />
      ),
      disabled: false,
      des: "Sync Fynd customers with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
      mappingDes:
        "Sync Fynd customers with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
    },
    {
      key: "3",
      label: "Products",
      icon: <ProductIcon />,
      component: (
        <Products
          {...{
            tabActive,
            setTabActive,
            syncCustomLoader,
            setSyncCustomLoader,
            reload,
            setReload,
            searchValue,
          }}
        />
      ),
      disabled: false,
      des: "Sync Fynd products with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
      mappingDes:
        "Sync Fynd products with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
    },
    {
      key: "4",
      label: "Orders",
      icon: <OrderIcon />,
      component: (
        <Orders
          {...{
            tabActive,
            setTabActive,
            syncCustomLoader,
            setSyncCustomLoader,
            reload,
            setReload,
            searchValue,
          }}
        />
      ),
      disabled: false,
      des: "Sync Fynd orders with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
      mappingDes:
        "Sync Fynd orders with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
    },
    {
      key: "5",
      label: "Payment Methods",
      icon: <PaymentIcon />,
      component: (
        <Payments
          {...{
            tabActive,
            setTabActive,
            syncCustomLoader,
            setSyncCustomLoader,
            reload,
            setReload,
            searchValue,
          }}
        />
      ),
      disabled: false,
      des: "Sync Fynd payment methods with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
      mappingDes:
        "Sync Fynd payment methods with Odoo instantly using Sync Now! and map fields efficiently with Manual Mapping for streamlined integration.",
    },
  ];

  const onChangeTab = (activeKey) => {
    const tab = tabList.find((t) => t.key === activeKey);
    if (!tab.disabled) {
      setTabActive(activeKey);
      setMappingComponent(false);
      setSearchValue("");
      handleSearch.cancel();
    }
  };

  const onMappingClick = () => {
    setMappingComponent(true);
  };

  const onRouteClick = () => {
    setMappingComponent(false);
  };

  const onHomeClick = () => {
    setTabActive("0");
    setMappingComponent(false);
  };

  const handleSearch = useCallback(
    debounce((label, e) => getSearchData(label, e), 500),
    []
  );

  const getSearchData = async (label, e) => {
    if (label === "Customers") {
      setSearchValue(e?.target?.value);
    }
    if (label === "Products") {
      setSearchValue(e?.target?.value);
    }
    if (label === "Orders") {
      setSearchValue(e?.target?.value);
    }
    if (label === "Payment Methods") {
      setSearchValue(e?.target?.value);
    }
  };

  const syncAllDetails = async (label) => {
    setSyncCustomLoader(true);
    let response;
    try {
      if (label === "Customers") {
        response = await MainService.syncAllCustomersDetail(application_id);
      }
      if (label === "Products") {
        response = await MainService.syncAllProductsDetail(application_id);
      }

      if (label === "Orders") {
        response = await MainService.syncAllOrdersDetail(application_id);
      }
      if (label === "Payment Methods") {
        response = await MainService.syncAllPaymentsDetail(application_id);
      }
      if (response.status === 200) {
        setSyncCustomLoader(false);
        if (label === "Customers") {
          setReload(true);
        }
        if (label === "Products") {
          setReload(true);
        }
        if (label === "Orders") {
          setReload(true);
        }
        if (label === "Payment Methods") {
          setReload(true);
        }
      }
    } catch (error) {
      setSyncCustomLoader(false);
      const { response } = error;
      message.error(`Failed to sync ${label} data: ${response.data.message}`);
    }
  };

  const syncPopup = async (label) => {
    let response;
    if (label === "Customers") {
      response = await MainService.getTotalSyncCustomer(application_id);
    }
    if (label === "Products") {
      response = await MainService.getTotalSyncProduct(application_id);
    }
    if (label === "Orders") {
      response = await MainService.getTotalSyncOrders(application_id);
    }
    if (label === "Payment Methods") {
      response = await MainService.getTotalSyncPayments(application_id);
    }

    if (response.data.count === 0) {
      message.info(`There is nothing left to sync for ${label}.`);
    } else {
      confirm({
        title: `Sync ${label} Data`,
        icon: <SyncOutlined />,
        content: `Are you truly interested in syncing Odoo Platform's ${response.data.count} ${label} records?`,
        okText: "Sync",
        onOk() {
          syncAllDetails(label);
        },
        onCancel() { },
      });
    }
  };

  useEffect(() => {
    return () => {
      handleSearch.cancel(); // Ensure debounce is canceled when the component unmounts
    };
  }, []);

  return (
    <div className='relative'>
      <Tabs activeKey={tabActive} onChange={onChangeTab}>
        {tabList.map((tab) => (
          <TabPane
            key={tab.key}
            tab={
              <div
                className={`flex items-center ${tab.disabled ? "disabled-tab" : ""
                  }`}
              >
                {tab.icon}
                <span className='ml-2 text-sm font-normal'>{tab.label}</span>
              </div>
            }
          >
            {mappingComponent && (
              <div className='my-4 mx-5 flex justify-start items-center cursor-pointer'>
                <HomeIcon className='mb-1 mr-1' />
                <p
                  className='text-sm font-normal text-[#00000073] mr-2'
                  onClick={onHomeClick}
                >
                  Home
                </p>
                /
                <p
                  className='text-sm font-normal text-[#00000073] mx-2'
                  onClick={onRouteClick}
                >
                  {tab.label}
                </p>
                /
                <p className='text-sm font-normal text-[#00000073] ml-2'>
                  Mannual Mapping
                </p>
              </div>
            )}
            <div className='m-5'>
              <div className='application-container p-12 mt-2 relative'>
                {mappingComponent ? (
                  <>
                    <Mapping
                      {...{
                        title: pluralize.singular(tab.label).toLowerCase(),
                        mappingDes: tab.mappingDes,
                        mappingComponent,
                        setMappingComponent,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div>
                      <h1 className='font-semibold text-[38px]'>{tab.label}</h1>
                      <div className='border-b-[3px] border-b-[#a90083] w-[100px]'></div>
                    </div>
                    <div className='mb-4'>
                      <div className='p-3 mt-2 relative border border-[#D9D9D9] rounded border-b-2 border-b-[#a90083]'>
                        <div className='flex justify-start items-center'>
                          <span className='p-3 text-xl border-r-2 border-r-[#ad0b88]'>
                            <InfoCircleOutlined />
                          </span>
                          <p className='pl-4 text-sm font-normal'>{tab.des}</p>
                        </div>
                      </div>
                    </div>
                    {(tab.label === "Customers" ||
                      tab.label === "Products" ||
                      tab.label === "Orders" ||
                      tab.label === "Payment Methods") && (
                        <div className='static top-[70px] right-[48px]'>
                          <div
                            className={`flex justify-end items-center absolute top-[220px] right-[55px]`}
                          >
                            <Search
                              placeholder={`Search by ${tab.label === "Products"
                                ? "Name"
                                : tab.label === "Customers"
                                  ? "Name or Email"
                                  : "Fynd Id"
                                }`}
                              className='mr-2'
                              onChange={(e) => {
                                setSearchValue(e.target.value); // Update the state with input value
                                handleSearch(tab.label, e);
                              }}
                              value={searchValue}
                              style={{ width: 200 }}
                            />
                            {
                              <Button className='mr-2' onClick={onMappingClick}>
                                Manual Mapping
                              </Button>
                            }
                            <Button
                              onClick={() => syncPopup(tab.label)}
                              className='bg-[#A90083] text-white border border-[#A90083] hover:bg-[#ff84e4] hover:text-white'
                            >
                              Sync Now!
                            </Button>
                          </div>
                        </div>
                      )}
                    {tab.component}
                  </>
                )}
              </div>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Header;
