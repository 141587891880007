import * as React from "react";

const OrderIcon = (props) => (
    React.createElement("svg", {
        width: 18,
        height: 16,
        viewBox: "0 0 18 16",
        fill: "currentcolor",
        xmlns: "http://www.w3.org/2000/svg",
        ...props
    },
        React.createElement("path", {
            d: "M4 3C4.02083 2.14583 4.3125 1.4375 4.875 0.875C5.4375 0.3125 6.14583 0.0208333 7 0C7.85417 0.0208333 8.5625 0.3125 9.125 0.875C9.6875 1.4375 9.97917 2.14583 10 3V5H12C12.5625 5.02083 13.0312 5.21875 13.4062 5.59375C13.7812 5.96875 13.9792 6.4375 14 7H12.5C12.4792 6.6875 12.3125 6.52083 12 6.5H2C1.6875 6.52083 1.52083 6.6875 1.5 7V13C1.52083 13.3125 1.6875 13.4792 2 13.5H5V15H2C1.4375 14.9792 0.96875 14.7812 0.59375 14.4062C0.21875 14.0312 0.0208333 13.5625 0 13V7C0.0208333 6.4375 0.21875 5.96875 0.59375 5.59375C0.96875 5.21875 1.4375 5.02083 2 5H4V3ZM5.5 5H8.5V3C8.47917 2.58333 8.33333 2.22917 8.0625 1.9375C7.77083 1.66667 7.41667 1.52083 7 1.5C6.58333 1.52083 6.22917 1.66667 5.9375 1.9375C5.66667 2.22917 5.52083 2.58333 5.5 3V5ZM16 8C16.5625 8.02083 17.0312 8.21875 17.4062 8.59375C17.7812 8.96875 17.9792 9.4375 18 10V14C17.9792 14.5625 17.7812 15.0312 17.4062 15.4062C17.0312 15.7812 16.5625 15.9792 16 16H8C7.4375 15.9792 6.96875 15.7812 6.59375 15.4062C6.21875 15.0312 6.02083 14.5625 6 14V10C6.02083 9.4375 6.21875 8.96875 6.59375 8.59375C6.96875 8.21875 7.4375 8.02083 8 8H16ZM10 10.5C9.97917 10.1875 9.8125 10.0208 9.5 10C9.1875 10.0208 9.02083 10.1875 9 10.5V11C9.02083 11.8542 9.3125 12.5625 9.875 13.125C10.4375 13.6875 11.1458 13.9792 12 14C12.8542 13.9792 13.5625 13.6875 14.125 13.125C14.6875 12.5625 14.9792 11.8542 15 11V10.5C14.9792 10.1875 14.8125 10.0208 14.5 10C14.1875 10.0208 14.0208 10.1875 14 10.5V11C13.9792 11.5625 13.7812 12.0312 13.4062 12.4062C13.0312 12.7812 12.5625 12.9792 12 13C11.4375 12.9792 10.9688 12.7812 10.5938 12.4062C10.2188 12.0312 10.0208 11.5625 10 11V10.5Z",
            fill: "currentcolor",
            // fillOpacity: 0.45
        })
    )
);


export default OrderIcon;
