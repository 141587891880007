import * as React from "react";

const RightArrowIcon = (props) => (
    React.createElement("svg", {
        width: 17,
        height: 12,
        viewBox: "0 0 17 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        ...props
    },
        React.createElement("path", {
            d: "M10.4688 10.75C10.3229 10.625 10.25 10.4583 10.25 10.25C10.25 10.0417 10.3229 9.86458 10.4688 9.71875L13.4375 6.75H0.75C0.291667 6.70833 0.0416667 6.44792 0 5.96875C0.0416667 5.53125 0.291667 5.29167 0.75 5.25H13.4375L10.4688 2.28125C10.1771 1.92708 10.1771 1.57292 10.4688 1.21875C10.8229 0.927083 11.1771 0.927083 11.5312 1.21875L15.7812 5.46875C16.0729 5.82292 16.0729 6.17708 15.7812 6.53125L11.5312 10.7812C11.1771 11.0729 10.8229 11.0625 10.4688 10.75Z",
            fill: "black",
            fillOpacity: 0.45
        })
    )
);


export default RightArrowIcon;
