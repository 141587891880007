import React, { useState, useEffect, useContext } from "react";
import "./style/dashboard.css";
import Loader from "../components/Loader";
import MainService from "../services/main-service";
import { useNavigate } from "react-router-dom";
import { UserStateContext } from "../context/UserContext";
import { DefaultSkeleton } from "../components/theme/loader/defaultSkeletonComponent";
import { Button, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { formatDate, getPlanInterval } from "../components/constant/helper";

export default function Home() {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const navigate = useNavigate();
  const { setApplicationId } = useContext(UserStateContext);
  const [configTabActive, setConfigTabActive] = useState("0");

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    setPageLoading(true);
    try {
      const { data } = await MainService.getAllApplications();
      const temp = data.items.map((ele) => {
        ele.text = ele.name;
        ele.value = ele._id;
        ele.image = ele.logo;
        ele.logo = ele.image && ele.image.secure_url;
        return ele;
      });
      setApplicationList(temp);

      const subscriptionDetail = await MainService.getSubscriptionDetails();
      setSubscriptionDetail(subscriptionDetail.data.data);

      setPageLoading(false);
    } catch (e) {
      setPageLoading(false);
    }
  };

  const clickApplication = async (id) => {
    setSelectionLoading(true)
    const data = await MainService.fyndAllDataInsert(id);

    if (data.status === 200) {
      setSelectionLoading(false)
      setApplicationId(id);
      navigate(`/auth`);
    }
    else {
      setSelectionLoading(false)
    }
  }

  const tabConfigList = [
    {
      key: "0",
      label: "Applications",
    },
    {
      key: "1",
      label: "Subscription Details",
    },
  ];

  const onChangeConfigTab = (activeKey) => {
    setConfigTabActive(activeKey);
  };

  return (
    <>
      {selectionLoading &&
        <DefaultSkeleton />
      }
      {pageLoading ? (
        <Loader />
      ) : (


        <div className="m-5">
          <Tabs activeKey={configTabActive} onChange={onChangeConfigTab}>
            {tabConfigList.map((tab) => (
              <TabPane
                key={tab.key}
                tab={
                  <div className={`flex items-center`}>
                    <span className="ml-2 text-sm font-normal">{tab.label}</span>
                  </div>
                }
              >
                <div className="application-container p-12 mt-6 relative">
                  {configTabActive === "0" ?

                    <div className="grid grid-cols-4 gap-6">
                      {applicationList.map((application, index) => (
                        <div
                          className="border border-[#e4e5e6] p-6 rounded-xl cursor-pointer"
                          key={index}
                          onClick={() => clickApplication(application._id)}
                        >
                          <div className="text-center">
                            <img
                              className="max-w-100 mx-auto"
                              src={application.logo ? application.logo : "https://platform.fynd.com/public/admin/assets/pngs/fynd-store.png"}
                              alt="logo"
                            />
                            <h2 className="line-1 font-semibold text-xl">{application.name}</h2>
                            <p className="text-sm font-normal">{application.domain.name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    :
                    <div className="max-w-4xl mx-auto p-6">
                      {/* Current Plan Section */}
                      <div className="bg-white p-6 rounded-md shadow-md border border-gray-200 mb-6">
                        <h2 className="text-lg font-bold mb-4">Current plan</h2>

                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-gray-700 font-medium">PLAN</h3>
                            <p className="text-lg font-semibold">{subscriptionDetail.plan.name}</p>
                            {/* <a href="#" className="text-blue-500 text-sm underline">
                              Upgrade to Business
                            </a> */}
                          </div>

                          <div>
                            <h3 className="text-gray-700 font-medium">BILLING CYCLE</h3>
                            <p className="text-lg font-semibold">{getPlanInterval(subscriptionDetail.plan.interval)}</p>
                            {/* <a href="#" className="text-blue-500 text-sm underline">
                              Switch to annual plan
                            </a>
                            <p className="text-green-500 text-xs mt-1">SAVE $144 PER YEAR</p> */}
                          </div>

                          <div>
                            <h3 className="text-gray-700 font-medium">BILLING DATE</h3>
                            <p className="text-sm">
                              <strong>Last billing:</strong> {formatDate(subscriptionDetail.subscription_start)}
                            </p>
                            <p className="text-sm">
                              <strong>Next billing:</strong> {formatDate(subscriptionDetail.subscription_start)}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Cancel Subscription Section */}
                      <div className="bg-white p-6 rounded-md shadow-md border border-gray-200">
                        <h2 className="text-lg font-bold mb-4">Cancel subscription</h2>
                        <p className="text-sm text-gray-600 mb-4">
                          Canceled subscription will remain active until the end of the current billing period.
                        </p>
                        {/* onClick={() => syncPopup(tab.label)} */}
                        <Button  className="bg-[#A90083] text-white border border-[#A90083] hover:bg-[#ff84e4] hover:text-white">
                          Cancel subscription
                        </Button>

                      </div>
                    </div>
                  }
                </div>
              </TabPane>
            ))
            }
          </Tabs >
        </div>
      )
      }
    </>
  );
}