let company_id = null;
let application_id = null;
let tab_active = null;

export const setCompany = (companyId) => {
  company_id = companyId;
};

export const getCompany = () => {
  return company_id;
};

export const setApplication = (applicationId) => {
  application_id = applicationId;
};

export const getApplication = () => {
  return application_id;
};

export const setTabActive = (tabActive) => {
  tab_active = tabActive;
};

export const getTabActive = () => {
  return tab_active;
};
