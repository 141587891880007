import React from "react";
import { Spin } from "antd";
import defaultSkeletonStyles from "./defaultSkeleton.module.css";

export const DefaultSkeleton = (props) => {
    const { isContent = false } = props;

    return (
        <div className={`${defaultSkeletonStyles.wrapper} spin-wrapper`}>
            <div
                className={`${isContent ? "" : defaultSkeletonStyles.backdrop}`}
            ></div>
            <div
                className={` ${isContent
                        ? "relative min-h-max my-auto flex justify-center items-center defaultSpinner"
                        : defaultSkeletonStyles.preLoader
                    }`}
            >
                <div className="relative text-white text-center z-10">
                    <Spin tip="Loading..." className="font-medium" />
                </div>
            </div>
        </div>
    );
};
