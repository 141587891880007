import * as React from "react";

const MappingIcon = (props) => (
    React.createElement("svg", {
        width: 50,
        height: 50,
        viewBox: "0 0 50 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        ...props
    },
        React.createElement("rect", {
            width: "50", height: "50", rx: "12", fill: "#FFF0F6",
            // fillOpacity: 0.45
        }),
        React.createElement("path", {
            d: "M16 19.0625C16.0234 18.7109 16.2109 18.5234 16.5625 18.5H21.0625C21.625 18.5234 22.082 18.7578 22.4336 19.2031L26.2305 24.4414C26.3477 24.582 26.5 24.6523 26.6875 24.6523H32.0664L30.2383 22.8242C30.0039 22.5664 30.0039 22.3086 30.2383 22.0508C30.4961 21.8164 30.7539 21.8164 31.0117 22.0508L33.8242 24.8633C34.0586 25.1211 34.0586 25.3789 33.8242 25.6367L31.0117 28.4492C30.7539 28.6836 30.4961 28.6836 30.2383 28.4492C30.0039 28.1914 30.0039 27.9336 30.2383 27.6758L32.0664 25.7773H26.6875C26.5 25.8008 26.3477 25.8945 26.2305 26.0586L22.4336 31.2969C22.082 31.7422 21.625 31.9766 21.0625 32H16.5625C16.2109 31.9766 16.0234 31.7891 16 31.4375C16.0234 31.0859 16.2109 30.8984 16.5625 30.875H21.0625C21.25 30.875 21.4023 30.793 21.5195 30.6289L25.3516 25.3906C25.3516 25.3672 25.3516 25.3555 25.3516 25.3555C25.375 25.332 25.4102 25.2852 25.457 25.2148C25.4102 25.1914 25.375 25.1562 25.3516 25.1094L21.5195 19.8711C21.4023 19.707 21.25 19.6133 21.0625 19.5898H16.5625C16.2109 19.5664 16.0234 19.3789 16 19.0273V19.0625Z",
            fill: "#A90083",
            // fillOpacity: 0.45
        })
    )
);


export default MappingIcon;
