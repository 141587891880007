import React, { useState, useEffect } from "react";
import "./views/style/dashboard.css";
import MainService from "./services/main-service";
import { DefaultSkeleton } from "./components/theme/loader/defaultSkeletonComponent";
import Home from "./views/Home";
import Auth from "./views/Auth";
import SubscriptionPlan from "./views/SubscriptionPlan";

function App() {
  const [loading, setLoading] = useState(true);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  
  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    try {
      const { data } = await MainService.getSubscription();
      
      if (data.code === 404 || data.status !== 'active') {
        setIsSubscriptionActive(false);
      } else {
        setIsSubscriptionActive(true);
      }
    } catch (error) {
      console.error("Error fetching subscription:", error);
      setIsSubscriptionActive(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <DefaultSkeleton />;
  }

  return isSubscriptionActive ? <Auth /> : <SubscriptionPlan />;
}

export default App;
