import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { EyeFilled } from "@ant-design/icons";
import "../../views/style/global.css";
import MainService from "../../services/main-service";
import { DEFAULT_TABLE_LIMIT, PriceSymbol } from "../constant/constant";
import TableComponent from "../theme/table/tableComponent";
import DetailModal from "../modal/detailModal";
import { DefaultSkeleton } from "../theme/loader/defaultSkeletonComponent";
import Loader from "../Loader";
import { getApplication } from "../../helper/utils";

const Payments = (props) => {
  const {
    tabActive,
    syncCustomLoader,
    setSyncCustomLoader,
    reload,
    setReload,
    searchValue,
  } = props;
  const [paymentData, setPaymentData] = useState([]);
  const [paymentDetailData, setPaymentDetailData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_TABLE_LIMIT,
    total: 0,
  });
  const [paymentSorter, setPaymentSorter] = useState({
    field: "_id",
    order: "asc",
  });
  const { confirm } = Modal;
  const [customLoader, setCustomLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const application_id = getApplication();

  const fetchPaymentData = async (
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) => {
    setReload(false);
    setCustomLoader(true);
    try {
      const response = await MainService.getAllPayments(
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue
      );
      if (response.status === 200) {
        setPaymentData(response.data.data);
        setPagination({
          ...pagination,
          current: pageNumber,
          pageSize: pageSize,
          total: response.data.total,
        });
        setCustomLoader(false);
      }
    } catch (error) {
      setCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };

  const handleTableChange = async (paginationData, filters, sorterData) => {
    setSyncCustomLoader(true);
    let sortField;
    let sortOrder;
    if (paginationData?.sorter["orderBy"]) {
      sortField = paginationData?.sorter["orderBy"].split("|")[0];
      sortOrder = paginationData?.sorter["orderBy"].split("|")[1];
    } else {
      sortField = paymentSorter.field;
      sortOrder = paymentSorter.order;
    }

    setPaymentSorter({ field: sortField, order: sortOrder });

    const response = await MainService.getAllPayments(
      application_id,
      paginationData.pagination.current,
      paginationData.pagination.pageSize,
      sortField,
      sortOrder,
      searchValue
    );

    if (response.status === 200) {
      setPaymentData(response.data.data);
      setPagination({
        ...pagination,
        current: paginationData.pagination.current,
        pageSize: paginationData.pagination.pageSize,
        total: response.data.total,
      });
      setSyncCustomLoader(false);
    }
  };

  useEffect(() => {
    if (searchValue) {
      setPagination({ current: 1, pageSize: 10 });
    }
    if (tabActive === "5") {
      fetchPaymentData(
        pagination.current,
        pagination.pageSize,
        paymentSorter.field,
        paymentSorter.order,
        searchValue
      );
    }
  }, [reload, tabActive, searchValue]);

  const paymentListColumns = [
    {
      title: "ID",
      dataIndex: "_id",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Total Payment Amount",
      dataIndex: "amount_in_paisa",
      sorter: true,
      render: (text, record) => {
        const currencySymbol = PriceSymbol[record.currency] || "";
        const amountInRupiya = text ? (text / 100).toFixed(2) : "-";
        return (
          <span>{text ? `${currencySymbol} ${amountInRupiya}` : "-"}</span>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      sorter: false,
      render: (text) => <span>{text ? text.split("T")[0] : "-"}</span>,
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "current_status",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Fynd ID",
      dataIndex: "fynd_id",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Odoo ID",
      dataIndex: "odoo_id",
      sorter: false,
      render: (text, record) => <span>{text ? text : <Button onClick={() => syncPaymentDetails(record)} className="mr-2">Sync</Button>}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      sorter: false,
      render: (actions, record) => getActions(record),
    },
  ];

  const syncPaymentDetails = async (record) => {
    setSyncCustomLoader(true);
    try {
      const response = await MainService.syncPaymentsDetail(record.application_id, record._id);

      if (response.status === 200) {
        setSyncCustomLoader(false);
        fetchPaymentData(pagination.current, pagination.pageSize, paymentSorter.field, paymentSorter.order, searchValue);
      }
    } catch (error) {
      setSyncCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  }
  const getActions = (record) => (
    <div className="flex items-center justify-start">
      <EyeFilled
        onClick={() => fetchPaymentDetails(record)}
        className="text-gray-400 cursor-pointer mr-2"
      />
      {/* <DeleteOutlined onClick={showConfirm} className="text-gray-400 cursor-pointer" /> */}
    </div>
  );

  const fetchPaymentDetails = async (record) => {
    setSyncCustomLoader(true);
    try {
      const response = await MainService.getPaymentDetails(
        application_id,
        record._id
      );

      if (response.status === 200) {
        setPaymentDetailData(response.data.data);
        setSyncCustomLoader(false);
        setModalOpen(true);
      }
    } catch (error) {
      setSyncCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };

  return (
    <div className="my-5">
      <div className="bg-white relative">
        <div className="mt-[84px]">
          {syncCustomLoader && <DefaultSkeleton />}
          {customLoader ? (
            <Loader />
          ) : (
            <>
              <TableComponent
                rowKey="payment"
                columns={paymentListColumns}
                dataSource={paymentData || []}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  showSizeChanger: false,
                }}
                onTableChange={handleTableChange}
              />
              <DetailModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                data={paymentDetailData}
                title="View Payment Details"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payments;
